/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. 
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import 'media-mixins';

.content-wrapper {
  min-height: 100%;
}

ion-modal.add-subject-modal,
ion-modal.nzqa-access-modal,
ion-modal.disclaimer-modal,
ion-modal.helpModal {
  --width: 500px;
}
ion-modal.calculator-info-modal {
  --width: 400px;
}
ion-modal.uncategorised-edit-modal {
  --width: 600px;
}
ion-modal.add-subject-modal,
ion-modal.nzqa-access-modal,
ion-modal.disclaimer-modal,
ion-modal.calculator-info-modal,
ion-modal.helpModal,
ion-modal.uncategorised-edit-modal {
  --height: auto !important;
  .ion-page {
    position: relative;
    contain: content;
    max-height: 90vh;
    overflow-y: auto;
  }
  @include phone {
    --height: 100% !important;
    --width: 100%;
    .ion-page {
      max-height: 100vh;
    }
  }
}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      color: var(--color-primary);
    }
  }
}
.alert-title.sc-ion-alert-md {
  color: var(--color-primary);
}
